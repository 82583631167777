import {
  Box,
  Button,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { DataType } from 'types';

export const ExtractedDataFieldRepeater = () => {
  const { control } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'dataToExtract',
  });

  return (
    <Box>
      {fields.map((field, index) => (
        <Grid container spacing={2} key={field.id} sx={{ mb: 2 }}>
          <Grid item xs={12} md={4}>
            <Controller
              name={`dataToExtract.${index}.name`}
              control={control}
              render={({ field }) => <TextField {...field} label="Field Name" fullWidth />}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Controller
              name={`dataToExtract.${index}.description`}
              control={control}
              render={({ field }) => <TextField {...field} label="Description" fullWidth />}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <Controller
              name={`dataToExtract.${index}.dataType`}
              control={control}
              render={({ field }) => (
                <FormControl fullWidth>
                  <InputLabel>Data Type</InputLabel>
                  <Select {...field} label="Data Type">
                    {Object.values(DataType).map((type) => (
                      <MenuItem key={type} value={type}>
                        {type}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            />
          </Grid>
          <Grid item xs={12} md={1} sx={{ display: 'flex', alignItems: 'center' }}>
            <IconButton onClick={() => remove(index)} color="error">
              <DeleteIcon />
            </IconButton>
          </Grid>
        </Grid>
      ))}
      <Button
        variant="outlined"
        onClick={() => append({ name: '', description: '', dataType: DataType.STRING })}
        startIcon={<AddIcon />}
      >
        Add Field
      </Button>
    </Box>
  );
};

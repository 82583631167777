import { RestartAlt } from '@mui/icons-material';
import {
  Box,
  Card,
  Container,
  createTheme,
  IconButton,
  Stack,
  ThemeOptions,
  ThemeProvider,
} from '@mui/material';
import { Credits } from 'components';
import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { MessageCard } from '../MessageCard';
import { ChatMetadata, Direction, Message, Sender } from '../types';
import { UserInput } from '../UserInput';
import {
  apiUrl,
  defaultThemeOptions,
  getRecaptchaToken,
  scrollToBottom,
  useLocalStorage,
} from '../utils';

export function Conversation({ playgroundMode = false }: { playgroundMode?: boolean }) {
  const [sessionID, setSessionID] = useLocalStorage('sessionID', uuidv4());

  const [chatMetadata, setChatMetadata] = useState<ChatMetadata>();
  const [messages, setMessages] = useState<Message[]>([]);
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const theme = useMemo(() => {
    const settings: ThemeOptions = {
      ...defaultThemeOptions,
      direction: chatMetadata?.direction === Direction.RTL ? 'rtl' : 'ltr',
      palette: {
        ...defaultThemeOptions.palette,
        primary: {
          ...defaultThemeOptions.palette?.primary,
          ...chatMetadata?.colors,
        },
      },
    };

    return createTheme(settings);
  }, [chatMetadata]);

  useEffect(() => {
    if (!chatMetadata) return;
    if (chatMetadata.direction === Direction.RTL && !playgroundMode) {
      document.dir = 'rtl';
    }
    document.title = chatMetadata.name;
    if (chatMetadata.conversations[0]?.messages.length) {
      for (const message of chatMetadata.conversations[0].messages) {
        appendMessages({
          sender: message.sender === 'ASSISTANT' ? Sender.Assistant : Sender.User,
          content: message.content,
        });
      }
    } else {
      setMessages([
        {
          sender: Sender.Assistant,
          content: chatMetadata.firstMessage,
        },
      ]);
    }
    return () => {
      document.dir = 'ltr';
    };
  }, [chatMetadata]);

  useEffect(() => {
    getChatMetadata();
  }, [sessionID]);

  function appendMessages(newMessages: Message | Message[]) {
    setMessages((prevMessages) => [
      ...prevMessages,
      ...(Array.isArray(newMessages) ? newMessages : [newMessages]),
    ]);
  }

  async function sendMessage(text: string) {
    if (loading || !chatMetadata) {
      return;
    }
    setLoading(true);
    appendMessages({ sender: Sender.User, content: text });
    if (!playgroundMode) {
      scrollToBottom();
    }
    if (messages.length === 1) {
      await startConversation();
    }
    const result = await postMessage({
      message: text,
      sessionID,
    });
    appendMessages({ ...result, sender: Sender.Assistant });
    setLoading(false);
    if (!playgroundMode) {
      scrollToBottom();
    }
  }

  async function startConversation() {
    await fetch(`${apiUrl}/web/start/${id}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + (await getRecaptchaToken()),
      },
      body: JSON.stringify({ sessionID }),
    });
  }

  async function postMessage(payload: { message: string; sessionID: string }): Promise<Message> {
    const result = await fetch(`${apiUrl}/web/message/${chatMetadata?.id}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + (await getRecaptchaToken()),
      },
      body: JSON.stringify(payload),
    });
    return result.json();
  }

  async function getChatMetadata() {
    const result = await fetch(`${apiUrl}/web/${id}?sessionID=web:${sessionID}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + (await getRecaptchaToken()),
      },
    });
    setChatMetadata(await result.json());
  }

  const textFieldLabel =
    messages.length === 1 ? chatMetadata?.textFieldLabel ?? 'כתבו הודעה על מנת להתחיל...' : '';

  const getMessagePadding = (message: Message, paddingDirection: 'right' | 'left'): number => {
    const isRTL = theme.direction === Direction.RTL.toLowerCase();
    const isMessageFromAssistant = message.sender === Sender.Assistant;

    if (paddingDirection === 'left') {
      return isRTL ? (isMessageFromAssistant ? 0 : 3) : isMessageFromAssistant ? 3 : 0;
    }

    return isRTL ? (isMessageFromAssistant ? 3 : 0) : isMessageFromAssistant ? 0 : 3;
  };

  async function restartConversation() {
    setLoading(false);
    setSessionID(uuidv4());
  }

  if (!chatMetadata) {
    return null;
  }

  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth="md" sx={{ height: '100%' }}>
        <Stack spacing={1} minHeight="100%">
          {playgroundMode && (
            <IconButton
              onClick={restartConversation}
              color="primary"
              sx={{ position: 'absolute', top: 24 }}
            >
              <RestartAlt />
            </IconButton>
          )}
          <Box
            position="sticky"
            top={0}
            left={0}
            right={0}
            height={32}
            sx={{
              background: 'linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%)',
            }}
          />
          <Stack spacing={1.5}>
            {messages.map((message, index) => (
              <Stack
                key={index}
                direction={message.sender === Sender.User ? 'row' : 'row-reverse'}
                paddingLeft={getMessagePadding(message, 'left')}
                paddingRight={getMessagePadding(message, 'right')}
              >
                <MessageCard message={message} showSources={chatMetadata.showSources} />
              </Stack>
            ))}
            {loading && (
              <Stack direction="row-reverse">
                <Card elevation={0} sx={{ background: theme.palette.grey[100], padding: 2 }}>
                  <img src="/loading.gif" alt="loading" width={50} />
                </Card>
              </Stack>
            )}
          </Stack>
          <Box flex={1} />
          <Stack
            position="sticky"
            bottom={0}
            bgcolor={theme.palette.background.paper}
            paddingY={2}
            gap={1}
          >
            <UserInput label={textFieldLabel} onSend={sendMessage} isLoading={loading} />
            <Credits />
          </Stack>
        </Stack>
      </Container>
    </ThemeProvider>
  );
}

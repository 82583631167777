import { useState } from 'react';

export const useLocalStorage = <T = string>(
  key: string,
  defaultValue: T,
): [T, (value: T) => void] => {
  const [value, setValue] = useState<T>(() => {
    const storedValue = localStorage.getItem(key);
    if (storedValue !== null) {
      try {
        return JSON.parse(storedValue) as T;
      } catch {
        return storedValue as unknown as T;
      }
    } else {
      return defaultValue;
    }
  });
  const setStoredValue = (value: T) => {
    setValue(value);
    try {
      localStorage.setItem(key, JSON.stringify(value));
    } catch {
      localStorage.setItem(key, String(value));
    }
  };
  return [value, setStoredValue];
};

import { Button } from 'components/shadcn/button';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from 'components/shadcn/dialog';
import { Skeleton } from 'components/shadcn/skeleton';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from 'components/shadcn/table';
import dayjs from 'dayjs';
import { ChevronLeft, Eye, MessageCircle } from 'lucide-react';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Conversation, Message } from 'types';
import { apiUrl, fetchWithAuth } from 'utils';

const Conversations = () => {
  const { chatId } = useParams();
  const [conversations, setConversations] = useState<Conversation[]>([]);
  const [selectedMetadata, setSelectedMetadata] = useState<unknown>(null);
  const [selectedMessages, setSelectedMessages] = useState<Message[]>([]);
  const [page, setPage] = useState(1);
  const [limit] = useState(50);
  const [totalConversations, setTotalConversations] = useState(0);
  const [extractedDataKeys, setExtractedDataKeys] = useState<string[]>([]);
  const [metadataKeys, setMetadataKeys] = useState<string[]>([]);
  const navigate = useNavigate();
  const totalPages = Math.ceil(totalConversations / limit);

  const getPageNumbers: () => (number | string)[] = () => {
    const pageNumbers = [];
    const maxPageButtons = 10;
    const halfRange = Math.floor(maxPageButtons / 2);
    let startPage = Math.max(2, page - halfRange);
    let endPage = Math.min(totalPages - 1, page + halfRange);

    if (page <= halfRange) {
      endPage = Math.min(totalPages - 1, maxPageButtons);
    }
    if (page + halfRange >= totalPages) {
      startPage = Math.max(2, totalPages - maxPageButtons + 1);
    }

    if (startPage > 2) {
      pageNumbers.push('start-ellipsis');
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(i);
    }

    if (endPage < totalPages - 1) {
      pageNumbers.push('end-ellipsis');
    }

    return pageNumbers;
  };

  useEffect(() => {
    const fetchConversations = async () => {
      try {
        const response = await fetchWithAuth(
          `${apiUrl}/conversation/${chatId}?page=${page}&limit=${limit}`,
        );
        const data = await response.json();
        setConversations(data.conversations);
        setTotalConversations(data.totalConversations);

        const metadataKeysSet = new Set<string>();
        for (const conversation of data.conversations) {
          if (!conversation.metadata || Array.isArray(conversation.metadata)) {
            continue;
          }
          for (const key of Object.keys(conversation.metadata)) {
            metadataKeysSet.add(key);
          }
        }
        setMetadataKeys(Array.from(metadataKeysSet));
        const extractedDataKeysSet = new Set<string>();
        for (const conversation of data.conversations) {
          if (!conversation.extractedData || Array.isArray(conversation.extractedData)) {
            continue;
          }
          for (const key of Object.keys(conversation.extractedData)) {
            extractedDataKeysSet.add(key);
          }
        }
        setExtractedDataKeys(Array.from(extractedDataKeysSet));
      } catch (error) {
        console.error('Error fetching conversations:', error);
      }
    };

    fetchConversations();
  }, [chatId, page, limit]);

  const handleNextPage = () => {
    if (page * limit < totalConversations) {
      setPage(page + 1);
    }
  };

  const handlePreviousPage = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  return (
    <div className="mx-auto py-6 px-4 lg:px-6 lg:max-w-screen-lg flex-grow self-start box-border w-full">
      <div className="flex flex-row gap-2 justify-start items-center mb-10">
        <Button variant="link" size="icon" onClick={() => navigate(-1)}>
          <ChevronLeft className="h-4 w-4" />
        </Button>
        <h1 className="text-4xl font-black text-secondary">CONVERSATIONS</h1>
      </div>
      <div className="rounded-md border">
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead>Username</TableHead>
              <TableHead className="min-w-60">Number of Messages</TableHead>
              <TableHead className="min-w-60">Last Message Time</TableHead>
              {metadataKeys.map((key) => (
                <TableHead key={key}>{key}</TableHead>
              ))}
              {extractedDataKeys.map((key) => (
                <TableHead key={key}>{key}</TableHead>
              ))}
              <TableHead>Actions</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {!conversations.length &&
              Array.from({ length: limit }).map((_, index) => (
                <TableRow key={`skeleton-${index}`}>
                  <TableCell>
                    <Skeleton className="h-4 w-24" />
                  </TableCell>
                  <TableCell>
                    <Skeleton className="h-4 w-12" />
                  </TableCell>
                  <TableCell>
                    <Skeleton className="h-4 w-32" />
                  </TableCell>
                  <TableCell>
                    <div className="flex gap-2">
                      <Skeleton className="h-8 w-8" />
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            {conversations.map((conversation) => (
              <TableRow key={conversation.id}>
                <TableCell>{conversation.username}</TableCell>
                <TableCell>{conversation.messages.length}</TableCell>
                <TableCell>
                  {dayjs(conversation.messages[conversation.messages.length - 1].createdAt).format(
                    'DD/MM/YYYY HH:mm',
                  )}
                </TableCell>
                {metadataKeys.map((key) => (
                  <TableCell key={key}>
                    {conversation.metadata &&
                    typeof conversation.metadata === 'object' &&
                    !Array.isArray(conversation.metadata) &&
                    conversation.metadata[key] !== undefined
                      ? String(conversation.metadata[key])
                      : ''}
                  </TableCell>
                ))}
                {extractedDataKeys.map((key) => (
                  <TableCell key={key}>
                    {conversation.extractedData &&
                    typeof conversation.extractedData === 'object' &&
                    !Array.isArray(conversation.extractedData) &&
                    conversation.extractedData[key] !== undefined
                      ? String(conversation.extractedData[key])
                      : ''}
                  </TableCell>
                ))}
                <TableCell>
                  <Button
                    variant="ghost"
                    size="icon"
                    onClick={() => setSelectedMetadata(conversation.metadata)}
                    aria-label="View Metadata"
                  >
                    <Eye className="h-4 w-4" />
                  </Button>
                  <Button
                    variant="ghost"
                    size="icon"
                    onClick={() => setSelectedMessages(conversation.messages)}
                    aria-label="View Messages"
                  >
                    <MessageCircle className="h-4 w-4" />
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>

      <div className="flex justify-between mt-4">
        <Button variant="outline" onClick={handlePreviousPage} disabled={page === 1}>
          Previous
        </Button>

        <div className="flex justify-center flex-grow gap-2">
          <Button variant={page === 1 ? 'secondary' : 'outline'} onClick={() => setPage(1)}>
            1
          </Button>
          {getPageNumbers().map((pageNumber, index) =>
            typeof pageNumber === 'string' ? (
              <span key={index}>&hellip;</span>
            ) : (
              <Button
                key={pageNumber}
                variant={pageNumber === page ? 'secondary' : 'outline'}
                onClick={() => setPage(pageNumber)}
              >
                {pageNumber}
              </Button>
            ),
          )}

          {totalPages > 1 && (
            <Button
              variant={page === totalPages ? 'secondary' : 'outline'}
              onClick={() => setPage(totalPages)}
            >
              {totalPages}
            </Button>
          )}
        </div>

        <Button variant="outline" onClick={handleNextPage} disabled={page === totalPages}>
          Next
        </Button>
      </div>

      <Dialog open={!!selectedMetadata} onOpenChange={() => setSelectedMetadata(null)}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Conversation Metadata</DialogTitle>
            <DialogDescription>
              <pre>{JSON.stringify(selectedMetadata, null, 2)}</pre>
            </DialogDescription>
          </DialogHeader>
          <DialogFooter>
            <Button variant="outline" onClick={() => setSelectedMetadata(null)}>
              Close
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>

      <Dialog open={!!selectedMessages?.length} onOpenChange={() => setSelectedMessages([])}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Conversation Messages</DialogTitle>
            <div className="overflow-auto max-h-[500px]">
              {selectedMessages.map((m) => (
                <div key={m.id} className="mb-4">
                  <p className="capitalize font-medium">{m.sender.toLowerCase()}</p>
                  <p className="text-sm text-gray-600">
                    {new Date(m.createdAt || '').toLocaleString()}
                  </p>
                  <p className="mt-1 whitespace-pre-wrap">{m.content}</p>
                </div>
              ))}
            </div>
          </DialogHeader>
          <DialogFooter>
            <Button variant="outline" onClick={() => setSelectedMessages([])}>
              Close
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default Conversations;

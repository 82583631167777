import { createContext, useState, ReactNode } from 'react';
import { useLocalStorage } from 'utils';

interface AuthData {
  canEditDataToExtract: boolean;
  canEditDynamicPrompts: boolean;
  isAuthenticated: boolean;
  userId: string;
  email: string;
  role: string;
  login: (token: string) => void;
  logout: () => void;
  saveCanEditDataToExtract: (canEditDataToExtract: boolean) => void;
  saveCanEditDynamicPrompts: (canEditDynamicPrompts: boolean) => void;
  saveEmail: (newEmail: string) => void;
  saveRole: (newRole: string) => void;
  saveUserId: (newUserId: string) => void;
}

interface AuthProviderProps {
  children: ReactNode;
}

const AuthContext = createContext<AuthData>({
  canEditDataToExtract: false,
  canEditDynamicPrompts: false,
  isAuthenticated: false,
  userId: '',
  email: '',
  role: '',
  login: () => {},
  logout: () => {},
  saveCanEditDataToExtract: () => {},
  saveCanEditDynamicPrompts: () => {},
  saveEmail: () => {},
  saveRole: () => {},
  saveUserId: () => {},
});

const AuthProvider = ({ children }: AuthProviderProps) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(() => {
    return !!localStorage.getItem('token');
  });
  const [canEditDataToExtract, setCanEditDataToExtract] = useLocalStorage(
    'canEditDataToExtract',
    false,
  );
  const [canEditDynamicPrompts, setCanEditDynamicPrompts] = useLocalStorage(
    'canEditDynamicPrompts',
    false,
  );
  const [userId, setUserId] = useLocalStorage('userId', '');
  const [email, setEmail] = useLocalStorage('email', '');
  const [role, setRole] = useLocalStorage('role', '');

  const login = (token: string) => {
    localStorage.setItem('token', token);
    setIsAuthenticated(true);
  };

  const logout = () => {
    localStorage.removeItem('token');
    setIsAuthenticated(false);
  };

  const saveEmail = (newEmail: string) => {
    setEmail(newEmail);
  };

  const saveRole = (newRole: string) => {
    setRole(newRole);
  };

  const saveUserId = (newUserId: string) => {
    setUserId(newUserId);
  };

  const saveCanEditDataToExtract = (canEditDataToExtract: boolean) => {
    setCanEditDataToExtract(canEditDataToExtract);
  };

  const saveCanEditDynamicPrompts = (canEditDynamicPrompts: boolean) => {
    setCanEditDynamicPrompts(canEditDynamicPrompts);
  };

  return (
    <AuthContext.Provider
      value={{
        canEditDataToExtract,
        canEditDynamicPrompts,
        email,
        isAuthenticated,
        login,
        logout,
        role,
        saveCanEditDataToExtract,
        saveCanEditDynamicPrompts,
        saveEmail,
        saveRole,
        saveUserId,
        userId,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthProvider };

import { RemoveCircle } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  IconButton,
  Stack,
  TextField,
  Typography,
} from '@mui/material';

import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { UpdateForm } from '../types';
import { Checkbox } from './shadcn/checkbox';

export const DynamicSystemPromptRepeater = () => {
  const { control } = useFormContext<UpdateForm>();

  const {
    fields: dynamicPromptFields,
    insert: insertDynamicPrompt,
    remove: removeDynamicPrompt,
  } = useFieldArray({
    control,
    keyName: 'fieldId',
    name: 'dynamicSystemPrompts',
  });

  const {
    fields: promptVariableFields,
    insert: insertPromptVariable,
    remove: removePromptVariable,
  } = useFieldArray({
    control,
    keyName: 'fieldId',
    name: 'promptVariables',
  });

  return (
    <Stack p={2} spacing={3}>
      <Typography variant="h6">Dynamic Prompts</Typography>
      <Stack direction="row" alignItems="center">
        <Typography flexGrow={1}>
          Dynamic prompts are system prompts that vary based on the conversation metadata.
        </Typography>
        <Button
          color="success"
          variant="outlined"
          onClick={() =>
            insertDynamicPrompt(dynamicPromptFields.length, {
              prompt: '',
              name: '',
              condition: '',
              zIndex: 0,
            })
          }
          disableElevation
          disableRipple
        >
          <Typography align="center">＋ Add</Typography>
        </Button>
      </Stack>
      <Stack spacing={2} alignItems="center" justifyContent="center">
        <Box flexGrow={1} width="100%">
          {dynamicPromptFields?.map((field, index) => (
            <Accordion key={field.fieldId}>
              <AccordionSummary>
                <Typography alignSelf="center">
                  {field.name || `Dynamic System Prompt ${index + 1}`}
                </Typography>
                <Box flexGrow={1} />
                <IconButton aria-label="delete" onClick={() => removeDynamicPrompt(index)}>
                  <RemoveCircle />
                </IconButton>
              </AccordionSummary>
              <AccordionDetails>
                <Stack spacing={2}>
                  <Stack spacing={2} direction="row">
                    <Controller
                      name={`dynamicSystemPrompts.${index}.name`}
                      render={({ field }) => (
                        <TextField
                          fullWidth
                          label="Name"
                          {...field}
                          id={`dynamicSystemPrompts[${index}].name`}
                          type="text"
                          name={`dynamicSystemPrompts[${index}].name`}
                          defaultValue={field.value}
                        />
                      )}
                      control={control}
                      defaultValue={field.name}
                    />
                    <Controller
                      name={`dynamicSystemPrompts.${index}.condition`}
                      render={({ field }) => (
                        <TextField
                          fullWidth
                          label="Condition"
                          {...field}
                          id={`dynamicSystemPrompts[${index}].condition`}
                          type="text"
                          name={`dynamicSystemPrompts[${index}].condition`}
                          defaultValue={field.value}
                        />
                      )}
                      control={control}
                      defaultValue={field.condition}
                    />
                    <Controller
                      render={({ field }) => (
                        <TextField
                          label="Z-Index"
                          {...field}
                          id={`dynamicSystemPrompts[${index}].zIndex`}
                          type="number"
                          name={`dynamicSystemPrompts[${index}].zIndex`}
                          defaultValue={field.value}
                        />
                      )}
                      name={`dynamicSystemPrompts.${index}.zIndex`}
                      control={control}
                      defaultValue={field.zIndex}
                    />
                  </Stack>
                  <Controller
                    render={({ field }) => (
                      <TextField
                        fullWidth
                        label="Prompt"
                        {...field}
                        rows={20}
                        multiline
                        id={`dynamicSystemPrompts[${index}].prompt`}
                        type="text"
                        name={`dynamicSystemPrompts[${index}].prompt`}
                        defaultValue={field.value}
                      />
                    )}
                    name={`dynamicSystemPrompts.${index}.prompt`}
                    control={control}
                    defaultValue={field.prompt}
                  />
                </Stack>
              </AccordionDetails>
            </Accordion>
          ))}
        </Box>
      </Stack>
      <Typography variant="h6">Prompt Variables</Typography>
      <Stack direction="row" alignItems="center">
        <Typography flexGrow={1}>
          Prompt variables are placeholders that can be injected into prompts.
        </Typography>
        <Button
          color="success"
          variant="outlined"
          onClick={() =>
            insertPromptVariable(promptVariableFields.length, {
              name: '',
              value: '',
              injectToStartOfSystemPrompt: false,
              injectToEndOfSystemPrompt: false,
              injectToStartOfDynamicPrompt: false,
              injectToEndOfDynamicPrompt: false,
            })
          }
          disableElevation
          disableRipple
        >
          <Typography align="center">＋ Add Variable</Typography>
        </Button>
      </Stack>
      <Stack spacing={2} alignItems="center" justifyContent="center">
        <Box flexGrow={1} width="100%">
          {promptVariableFields?.map((field, index) => (
            <Accordion key={field.fieldId}>
              <AccordionSummary>
                <Typography alignSelf="center">
                  {field.name || `Prompt Variable ${index + 1}`}
                </Typography>
                <Box flexGrow={1} />
                <IconButton aria-label="delete" onClick={() => removePromptVariable(index)}>
                  <RemoveCircle />
                </IconButton>
              </AccordionSummary>
              <AccordionDetails>
                <Stack spacing={2}>
                  <Controller
                    name={`promptVariables.${index}.name`}
                    render={({ field }) => (
                      <TextField
                        fullWidth
                        label="Name"
                        {...field}
                        id={`promptVariables[${index}].name`}
                        type="text"
                        name={`promptVariables[${index}].name`}
                        defaultValue={field.value}
                      />
                    )}
                    control={control}
                    defaultValue={field.name}
                  />
                  <Controller
                    name={`promptVariables.${index}.value`}
                    render={({ field }) => (
                      <TextField
                        fullWidth
                        label="Value"
                        {...field}
                        rows={4}
                        multiline
                        id={`promptVariables[${index}].value`}
                        type="text"
                        name={`promptVariables[${index}].value`}
                        defaultValue={field.value}
                      />
                    )}
                    control={control}
                    defaultValue={field.value}
                  />
                  <Stack spacing={2} direction="row">
                    <Controller
                      name={`promptVariables.${index}.injectToStartOfSystemPrompt`}
                      render={({ field }) => (
                        <Stack direction="row" alignItems="center" gap={1} flexGrow={1}>
                          <Checkbox
                            {...field}
                            checked={field.value}
                            onCheckedChange={(value) => field.onChange(!!value.valueOf())}
                            value={undefined}
                            id={`promptVariables[${index}].injectToStartOfSystemPrompt`}
                            name={`promptVariables[${index}].injectToStartOfSystemPrompt`}
                          />
                          <label htmlFor={`promptVariables[${index}].injectToStartOfSystemPrompt`}>
                            Inject to Start of System Prompt
                          </label>
                        </Stack>
                      )}
                      control={control}
                      defaultValue={field.injectToStartOfSystemPrompt}
                    />
                    <Controller
                      name={`promptVariables.${index}.injectToEndOfSystemPrompt`}
                      render={({ field }) => (
                        <Stack direction="row" alignItems="center" gap={1} flexGrow={1}>
                          <Checkbox
                            {...field}
                            checked={field.value}
                            onCheckedChange={(value) => field.onChange(!!value.valueOf())}
                            value={undefined}
                            id={`promptVariables[${index}].injectToEndOfSystemPrompt`}
                            name={`promptVariables[${index}].injectToEndOfSystemPrompt`}
                          />
                          <label htmlFor={`promptVariables[${index}].injectToEndOfSystemPrompt`}>
                            Inject to End of System Prompt
                          </label>
                        </Stack>
                      )}
                      control={control}
                      defaultValue={field.injectToEndOfSystemPrompt}
                    />
                    <Box flexGrow={1} />
                  </Stack>
                  <Stack spacing={2} direction="row">
                    <Controller
                      name={`promptVariables.${index}.injectToStartOfDynamicPrompt`}
                      render={({ field }) => (
                        <Stack direction="row" alignItems="center" gap={1} flexGrow={1}>
                          <Checkbox
                            {...field}
                            checked={field.value}
                            onCheckedChange={(value) => field.onChange(!!value.valueOf())}
                            value={undefined}
                            id={`promptVariables[${index}].injectToStartOfDynamicPrompt`}
                            name={`promptVariables[${index}].injectToStartOfDynamicPrompt`}
                          />
                          <label htmlFor={`promptVariables[${index}].injectToStartOfDynamicPrompt`}>
                            Inject to Start of Dynamic Prompt
                          </label>
                        </Stack>
                      )}
                      control={control}
                      defaultValue={field.injectToStartOfDynamicPrompt}
                    />
                    <Controller
                      name={`promptVariables.${index}.injectToEndOfDynamicPrompt`}
                      render={({ field }) => (
                        <Stack direction="row" alignItems="center" gap={1} flexGrow={1}>
                          <Checkbox
                            {...field}
                            checked={field.value}
                            onCheckedChange={(value) => field.onChange(!!value.valueOf())}
                            value={undefined}
                            id={`promptVariables[${index}].injectToEndOfDynamicPrompt`}
                            name={`promptVariables[${index}].injectToEndOfDynamicPrompt`}
                          />
                          <label htmlFor={`promptVariables[${index}].injectToEndOfDynamicPrompt`}>
                            Inject to End of Dynamic Prompt
                          </label>
                        </Stack>
                      )}
                      control={control}
                      defaultValue={field.injectToEndOfDynamicPrompt}
                    />
                    <Box flexGrow={1} />
                  </Stack>
                </Stack>
              </AccordionDetails>
            </Accordion>
          ))}
        </Box>
      </Stack>
    </Stack>
  );
};
